import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ScaleInput } from '@app/form/interfaces/scale-input';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-scale-input',
    template: `
        <div class="u-bordered u-flex u-padding">
            <form class="u-margin-0-auto" [formGroup]="form" #scaleForm="ngForm">
                <app-select-input
                    width="auto"
                    [id]="id"
                    [label]="label"
                    [placeholder]="placeholder"
                    [formControlName]="formControlName"
                    [autoFocus]="autoFocus"
                    [hasMargin]="hasMargin"
                    [hasMarginDouble]="hasMarginDouble"
                    [options]="options"
                    [custom]="custom"
                    [ignoreMinWidth]="ignoreMinWidth"
                    [direction]="direction"
                ></app-select-input>
            </form>
        </div>
    `,
    styleUrls: ['./scale-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ScaleInputComponent),
            multi: true,
        },
    ],
})
export class ScaleInputComponent extends BaseInput implements ControlValueAccessor, OnInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public options: ScaleInput<any>[];
    @Input() public custom = true;
    @Input() public ignoreMinWidth = false;
    @Input() public direction: 'horizontal' | 'vertical';
    @Input() public form: UntypedFormGroup;

    @ViewChild('scaleForm', { static: true }) public formGroupDirective: FormGroupDirective;

    private innerModel: any;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public writeValue(value: any): void {
        this.innerModel = value;
    }

    public markAsSubmitted(): void {
        this.formGroupDirective.onSubmit(null);
    }
}
