import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ScaleGroup } from '@app/surveys/interfaces/meta/questions/scale-group';
import { TranslateService } from '@ngx-translate/core';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-score-input',
    template: `
        <app-label-container [required]="required" [bold]="labelBold" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <div class="__label-container">
                <div class="__score-group u-flex-column u-flex-align-items-left u-padding-top-half" *ngFor="let group of groups">
                    <div class="__label-container u-flex-row">
                        <label *ngFor="let scale of group.scales" class="u-margin-right">
                            <input
                                class="__input"
                                type="radio"
                                [(ngModel)]="innerModel"
                                (ngModelChange)="onModelChange()"
                                [value]="scale?.value"
                                [name]="id"
                                [disabled]="innerDisabled"
                            />
                            <div class="__container is-{{ group.type }} u-flex-row u-flex-align-items-center u-flex-justify-content-center">
                                <span>{{ scale.label[translateService.currentLang] }}</span>
                            </div>
                        </label>
                    </div>
                    <div class="__score-group-label u-color-muted u-font-size-small u-padding-top-half">
                        {{ group.label[translateService.currentLang] }}
                    </div>
                </div>
            </div>
            <mat-error class="u-margin-top-half" *ngIf="isErrorState()">{{ getFirstError() | translate }}</mat-error>
        </app-label-container>
    `,
    styleUrls: ['./score-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ScoreInputComponent),
            multi: true,
        },
    ],
})
export class ScoreInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public labelBold = false;
    @Input() public formControlName: string;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public groups: ScaleGroup[];

    public innerModel: number;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, public translateService: TranslateService) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: number): void {
        this.innerModel = value;
    }

    public onModelChange(): void {
        this.propagateChange(this.innerModel);
    }
}
