import { AfterViewInit, Component, forwardRef, Host, Input, OnChanges, OnInit, Optional, SimpleChanges, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RealEstateAgentOffice } from '@app/real-estate-agent/interfaces/real-estate-agent-office';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { BaseInput } from '@smooved/ui';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-offices-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <ng-autocomplete
                [data]="offices"
                [placeholder]="'OFFICE.LABEL' | translate"
                searchKeyword="name"
                [notFoundText]="'NOT_FOUND' | translate"
                (inputCleared)="inputClear($event)"
                (selected)="selected($event)"
                [isLoading]="loading$ | async"
                [(ngModel)]="innerModel"
                [name]="'real-estate-group'"
                [itemTemplate]="itemTemplateStatic"
            >
            </ng-autocomplete>

            <ng-template #itemTemplateStatic let-item>
                <span [innerHTML]="item?.name"></span>
            </ng-template>

            <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
        </app-label-container>
    `,
    styleUrls: ['./offices-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OfficesInputComponent),
            multi: true,
        },
    ],
})
export class OfficesInputComponent extends BaseInput implements ControlValueAccessor, OnChanges, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public realEstateGroupId: string;

    public offices: RealEstateAgentOffice[] = [];

    public innerModel = '';

    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private realEstateGroupSandbox: RealEstateGroupSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('realEstateGroupId')) {
            if (changes.realEstateGroupId.currentValue) {
                this.loadingSubject.next(true);
                this.realEstateGroupSandbox.getOffices({ _id: changes.realEstateGroupId.currentValue }).subscribe(
                    (offices) => {
                        this.offices = offices;
                    },
                    (_) => {},
                    () => this.loadingSubject.next(false)
                );
            } else {
                this.offices = [];
                this.innerModel = '';
                this.propagateChange(null);
            }
        }
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value): void {
        // first time we need to set the innerModel as the query ( name ). Otherwise the lib will throw an error?
        this.innerModel = value?.name || '';
    }

    public inputClear(e) {
        setTimeout(() => {
            this.propagateChange(null);
        }, 0);
    }

    public selected(e) {
        setTimeout(() => {
            this.propagateChange(this.innerModel);
        }, 0);
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }
}
