import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInput } from '@app/form/interfaces/file-input';
import { Invoice } from '@app/invoicing/interfaces/invoice';
import { BaseInput } from '@smooved/ui';
import { merge } from 'lodash';

@Component({
    selector: 'app-invoicing-asset-input',
    template: `
        <div class="u-margin-bottom u-flex-row u-flex-wrap u-flex-align-items-center">
            <!--            <app-thumbnail [file]="invoice?.asset"-->
            <!--                           *ngIf="!!invoice?.asset"-->
            <!--                           class="u-margin-right"-->
            <!--                           (detail)="detail.emit($event)"></app-thumbnail>-->

            <app-thumbnail [isPreview]="true" *ngIf="!!thumbnail" class="u-margin-right" [file]="thumbnail"></app-thumbnail>
        </div>

        <div class="u-flex-row">
            <app-file-input
                id="image"
                width="auto"
                class="u-margin-right"
                [(ngModel)]="innerModel"
                [label]="'INVOICING.SELECT_INVOICE' | translate"
                (change)="onChange($event)"
            ></app-file-input>

            <app-button *ngIf="!!file" (onClick)="onUpload()">{{ 'INVOICING.UPLOAD_INVOICE' | translate }}</app-button>
        </div>
    `,
    styleUrls: ['./invoicing-asset-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InvoicingAssetInputComponent),
            multi: true,
        },
    ],
})
export class InvoicingAssetInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public formControlName: string;
    @Input() public invoice: Invoice;

    @Output() public preview: EventEmitter<void> = new EventEmitter<void>();
    @Output() public detail: EventEmitter<FileInput> = new EventEmitter<FileInput>();
    @Output() public upload: EventEmitter<FormData> = new EventEmitter<FormData>();

    public file: File;
    public thumbnail: FileInput;

    public innerModel: any;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, private cdr: ChangeDetectorRef) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: string): void {
        this.innerModel = value;
    }

    public onChange(event): void {
        const file: File = event.target.files[0];
        if (file) {
            this.file = file;
            this.thumbnail = null;

            const reader = new FileReader();
            reader.onload = (_) => {
                this.thumbnail = merge(file, {
                    value: reader.result,
                });
                this.cdr.detectChanges();
            };
            reader.readAsDataURL(file);
        }
        this.onModelChange();
    }

    public onUpload(): void {
        if (this.file) {
            const formData = new FormData();
            formData.append('file', this.file, this.file['name']);
            this.upload.emit(formData);
        }
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
