import { Component, ElementRef, forwardRef, Host, Input, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MultiSelectInput } from '@app/form/interfaces/multi-select-input';
import { Selection } from '@app/surveys/interfaces/meta/questions/selection';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-multi-select-input',
    template: `
        <div *ngFor="let option of options; let last = last">
            <mat-radio-group [(ngModel)]="innerModel" [disabled]="innerDisabled" class="u-flex-column" (ngModelChange)="onModelChange()">
                <mat-radio-button
                    [id]="option?.id"
                    [disableRipple]="true"
                    class="u-margin-bottom-half"
                    [value]="option?.value"
                    [name]="option?.name"
                    [disabled]="option?.disabled"
                >
                    <div class="u-flex-row u-flex-align-items-center">
                        <span>{{ option?.label }}</span>
                        <span
                            class="u-color-muted u-font-size-small u-padding-left-half"
                            [innerHTML]="option?.description"
                            *ngIf="option?.description"
                        ></span>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
            <div class="u-margin-left-double" [class.u-disabled]="innerModel !== option?.value" [formGroup]="group.get('other')">
                <app-select-input
                    [custom]="false"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    [formControlName]="'detail'"
                    [label]="label"
                    [options]="option.children"
                ></app-select-input>
            </div>
        </div>
        <mat-error class="u-margin-top-half" *ngIf="isErrorState()">{{ getFirstError() | translate }}</mat-error>
    `,
    styleUrls: ['./multi-select-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectInputComponent),
            multi: true,
        },
    ],
})
export class MultiSelectInputComponent extends BaseInput implements ControlValueAccessor {
    @Input() public question: Selection;
    @Input() public formControlName: string;
    @Input() public options: MultiSelectInput<any>[];
    @Input() public group: UntypedFormGroup;

    public innerModel: any;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        this.innerModel = this.question.options.find((option) =>
            option.children?.some((child) => child.value === this.group.get('value').value)
        )?.value;
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: any): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.group.get('other')?.get('detail').patchValue(null);
        this.propagateChange(this.innerModel);
    }
}
