import { AfterViewInit, Component, ElementRef, forwardRef, Host, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserSandbox } from '@app/user/sandboxes/user.sandbox';
import { BaseInput, DropdownInput } from '@smooved/ui';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-account-manager-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <ng-autocomplete
                [data]="options"
                [placeholder]="'ACCOUNT_MANAGER' | translate"
                searchKeyword="label"
                [notFoundText]="'NOT_FOUND' | translate"
                (inputCleared)="inputClear($event)"
                (selected)="selected($event)"
                [isLoading]="loading$ | async"
                [(ngModel)]="innerModel"
                name="account-manager"
                [itemTemplate]="itemTemplateStatic"
            >
            </ng-autocomplete>

            <ng-template #itemTemplateStatic let-item>
                <span [innerHTML]="item?.label"></span>
            </ng-template>

            <mat-error *ngIf="showError()">{{ getFirstError() | translate }}</mat-error>
        </app-label-container>
    `,
    styleUrls: ['./account-manager-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccountManagerInputComponent),
            multi: true,
        },
    ],
})
export class AccountManagerInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;

    public innerModel: any = '';

    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public options: DropdownInput<string>[];

    private initialValue: string;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, private userSandbox: UserSandbox) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.loadingSubject.next(true);
        this.userSandbox
            .getAccountManagerOptions(false)
            .pipe(finalize(() => this.loadingSubject.next(false)))
            .subscribe((options) => {
                this.options = options;
                if (this.initialValue) {
                    this.innerModel = this.options.find((x) => x.value === this.initialValue)?.label;
                }
            });
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(userId: string): void {
        if (!userId) return;
        this.initialValue = userId;
    }

    public inputClear(e) {
        setTimeout(() => {
            this.propagateChange(null);
        }, 0);
    }

    public selected(e) {
        setTimeout(() => {
            this.propagateChange(this.innerModel?.value);
        }, 0);
    }

    public showError(): boolean {
        if (!this.getAbstractControl()) {
            return false;
        }
        return this.getAbstractControl().invalid && this.controlContainer['submitted'];
    }
}
